<template>
  <div id="app">
    <router-view :key="key"></router-view>
  </div>
</template>


<script>
  export default {
    name: "App",
    computed: {
      key() {
        return this.$route.fullPath;
      }
    },
  };
  </script>

<style lang="less">
@import "styles/reset.css";
@import "styles/globalStyle.less";
#app {
  display: flex;
  flex-direction: column;
  .container {
    flex: 1;
  }
}
</style>