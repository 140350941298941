import Vue from 'vue';
import VueRouter from 'vue-router';
// import Store from '@/store';
// import path from '@/config/path';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      meta: {
        title: '枢智Linkzo',
        key: 'error'
      },
      name: 'adminPage',
      component: () => import('@/pages/error/errorPage.vue')
    },
    {
      path: '/sms/smsTemplate/:id?',
      name: 'smsPage',
      key: 'smsPage',
      component: () => import('@/pages/sms/smsTemplate.vue')
    },
    {
      path: '*', redirect: '/'
    }
  ]
});

// router.beforeEach((to, from, next) => {
//   document.title = (to.meta && to.meta.title) || '枢智Linkzo';
//   if (to.meta.needLogin) {
//     let userInfo = Store.state.user;
//     if (!userInfo) {
//       next(`${path.loginPage}?redirect=${encodeURIComponent(to.fullPath)}`);
//     } else {
//       next();
//     }
//   } else {
//     next();
//   }
// });

export default router;
