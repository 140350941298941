/*
 * @Author: baihuawei
 * @Date: 2022-08-04 13:30:25
 * @FilePath: /linkzo_officialwebsite/src/main.js
 * @Modify:
 * @Description:
 */

import Vue from 'vue';
import Router from '@/router';
import App from '@/App.vue';
import Store from '@/store';
import {
  Button,
  Select,
  Divider,
  Menu,
  MenuItem,
  Submenu,
  Tag,
  Tabs,
  Card,
  TabPane,
  Form,
  FormItem,
  Input,
  Radio,
  RadioGroup,
  Option,
  Carousel,
  CarouselItem,
  Dialog,
  Cascader,
  Message
} from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.config.productionTip = false;
Vue.prototype.IMG_PREFIX = 'https://img.linkzo.cn/officialWebsite/images/'

Vue.use(Button);
Vue.use(Select);
Vue.use(Divider);
Vue.use(Menu);
Vue.use(MenuItem);
Vue.use(Submenu);
Vue.use(Tag);
Vue.use(Tabs);
Vue.use(TabPane);
Vue.use(Card);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Input);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Option);
Vue.use(Carousel);
Vue.use(Cascader);
Vue.use(CarouselItem);
Vue.use(Dialog);

Vue.prototype.$message = Message;

// 路由跳转回到顶部
Router.beforeEach((to, from, next) => {    
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
  window.pageYOffset = 0;
  next();
})

new Vue({
  router: Router,
  store: Store,
  render: h => h(App)
}).$mount('#app');
